import { combineEpics } from 'redux-observable';
import { EMPTY, from, pipe } from 'rxjs';
import {
  debounceTime,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { AnalyticsEvent, triggerAnalyticsEvent } from 'logic/analytics/analytics';
import { Feature } from '../authentication/feature/authorization.feature';
import { API_MULTIPLY_CALLS_DEBOUNCE_MS, API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { CreateBookingType, elitetutorSlice } from 'logic/store/elitetutor/elitetutor.slice';
import { RootEpic } from 'app/app.epics.type';
import {
  ELITE_FORM_NAME,
  validateEliteForm,
} from 'src/pages-all/offer-form/elitetutor-offer-form/elitetutor-offer-form.logic';
import {
  mapBookingFormToRequest,
  mapBookingForAnyStudentFormToRequest,
  mapCreditTutorToForm,
  mapOfferFormToRequest,
} from 'logic/store/elitetutor/elitetutor.form.logic';
import { logError, logSuccess } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { navigationSlice } from 'logic/store/navigation/navigation.slice';
import { AppRoute } from 'app/app.route.const';
import { generatePath } from 'react-router-dom';
import { mapMyPricingFormToRequest } from 'src/pages-all/user/user-profile/my-pricing/my-pricing.logic';
import { isBadRequestError } from 'logic/error/bad-request.error';
import { getBookingListPayload, getCreditTutor } from 'logic/store/elitetutor/elitetutor.selectors';
import { initialize } from 'redux-form';
import {
  UPDATE_PAYOUT_INFO_FORM_NAME,
  validateUpdatePayoutInfo,
} from 'src/pages-all/user/user-profile/earnings/components/update-payout-info/update-payout-info.logic';
import { getAuthenticatedUser } from '../authentication/authentication.selectors';
import { modalSlice } from '../modal';
import {
  LessonTypePreset,
  ViewAs,
} from 'src/pages-all/user/user-profile/up-coming-lessons/UpComingLessons.types';
import { isConflictError, isForbiddenError } from 'logic/error/general-api-error';
import { authenticationSlice } from 'logic/store/authentication/authentication.slice';
import { isUnauthenticatedError } from 'logic/error/unauthenticated.error';
import { isNotFoundError } from 'logic/error/not-found.error';
import {
  EARN_MONEY_FORM_NAME,
  EarnMoneyFormValues,
  validateEarnMoneyForm,
} from 'src/pages-all/user/user-profile/earn-money/earn-money.logic';

const applyAsEliteViaForm$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, ofValidReduxForm, elitetutorApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.applyAsEliteViaForm.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.ApplyAsElite),
    ofValidReduxForm(ELITE_FORM_NAME, validateEliteForm),
    managed(
      elitetutorSlice.actions.applyAsEliteViaForm,
      pipe(
        map((action) => action.payload.formValues),
        switchMap((formValues) =>
          from(elitetutorApi.applyAsElite(mapOfferFormToRequest(formValues)))
        )
      )
    ),
    tap((response) => {
      triggerAnalyticsEvent(AnalyticsEvent.ApplyAsEliteSuccess);
      dispatch(elitetutorSlice.actions.setElitetutorState({ response: response.data.data }));
      // User.rights might have changed
      dispatch(authenticationSlice.actions.fetchCurrentUser());
    }),
    ignoreElements()
  );

const applyAsElite$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.applyAsElite.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.ApplyAsElite),
    managed(
      elitetutorSlice.actions.applyAsElite,
      pipe(
        map((action) => action.payload),
        switchMap(({ subjects }) => from(elitetutorApi.applyAsElite({ subjects })))
      )
    ),
    tap((response) => {
      triggerAnalyticsEvent(AnalyticsEvent.ApplyAsEliteSuccess);
      dispatch(elitetutorSlice.actions.setElitetutorState({ response: response.data.data }));
      // User.rights might have changed
      dispatch(authenticationSlice.actions.fetchCurrentUser());
    }),
    ignoreElements()
  );

const getSubjectApplications$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getSubjectApplications.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getSubjectApplications,
      pipe(
        map((action) => action.payload.userID),
        switchMap((userID) => from(elitetutorApi.getSubjectApplications(userID)))
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setSubjectApplications({ response: response.data.data }));
    }),
    ignoreElements()
  );

const createBooking$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.createBooking.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.CreateBooking),
    managed(
      elitetutorSlice.actions.createBooking,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          const createBooking = () =>
            payload.bookingType === CreateBookingType.BookByEmail
              ? elitetutorApi.createBookingForAnyStudent(
                  mapBookingForAnyStudentFormToRequest(
                    payload.formValues,
                    payload.tuteeEmail,
                    payload.price
                  ),
                  payload.tutorID
                )
              : elitetutorApi.createBooking(
                  mapBookingFormToRequest(payload.formValues, payload.tuteeID, payload.price),
                  payload.tutorID
                );
          return from(createBooking()).pipe(map((response) => ({ response, payload })));
        })
      ),
      [
        (error, action) => {
          const customErrorCode = error?.response?.data?.error;
          if (error && isBadRequestError(error) && customErrorCode === 'VALIDATION_ERROR') {
            logError(translate(translationKeys.bookingForm.create.responseBadRequestError));
            return true;
          }
          if (error && isNotFoundError(error)) {
            logError(translate(translationKeys.bookingForm.create.responseNotFoundError));
            return true;
          }
          if (
            error &&
            isConflictError(error) &&
            customErrorCode === 'BOOKING_CREDIT_INSUFFICIENT'
          ) {
            const subscription = error.response?.data?.context?.subscription;

            if (action.payload?.bookingType === CreateBookingType.BookByEmail) {
              logError(
                translate(
                  translationKeys.bookingForm.create.customErrorCode
                    .BOOKING_CREDIT_INSUFFICIENT_WITHOUT_PREVIOUS_BOOKING
                )
              );
              return true;
            }
            if (action.payload?.viewAs !== ViewAs.TUTOR) {
              logError(
                translate(
                  translationKeys.bookingForm.create.customErrorCode
                    .BOOKING_CREDIT_INSUFFICIENT_SEEKER
                )
              );
              return true;
            }
            if (subscription && subscription.autoRenew != null && subscription.currentPeriodEnd) {
              dispatch(
                elitetutorSlice.actions.setBookingCreditInsufficientInfo({
                  seekerId: action.payload.tuteeID,
                  autoRenew: subscription.autoRenew,
                  currentPeriodEnd: new Date(subscription.currentPeriodEnd),
                })
              );
            }
            dispatch(modalSlice.actions.openPromptCreditPurchaseModal());
            return true;
          }
          if (error && isConflictError(error) && customErrorCode === 'SEEKER_NOT_FOUND') {
            logError(
              translate(translationKeys.bookingForm.create.responseConflictSeekerNotFoundError)
            );
            return true;
          }
          return false;
        },
      ]
    ),
    tap(({ response, payload }) => {
      const viewAs = payload.viewAs || ViewAs.SEEKER;
      dispatch(elitetutorSlice.actions.setCreateBookingState({ response: response.data.data }));
      dispatch(
        elitetutorSlice.actions.setBookingsType({
          type: LessonTypePreset.ONGOING_OR_UPCOMING,
          viewAs,
        })
      );
      dispatch(
        navigationSlice.actions.navigateTo({
          path: `${generatePath(AppRoute.UnitOverview, { viewAs })}`,
        })
      );
      logSuccess(translate(translationKeys.bookingForm.create.responseOK));
    }),
    ignoreElements()
  );

const getElitetutorBookings$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getElitetutorBookings.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getElitetutorBookings,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          if (payload) {
            dispatch(elitetutorSlice.actions.setBookingsPayload(payload));
          } else {
            payload = getBookingListPayload(state$.value);
          }

          if (payload) {
            dispatch(
              elitetutorSlice.actions.setBookingsType({
                type: payload.preset,
                viewAs: ViewAs.TUTOR,
              })
            );
            return from(
              elitetutorApi.getElitetutorBookings(
                payload.userID,
                payload.size,
                payload.page,
                payload.preset
              )
            );
          } else {
            return EMPTY;
          }
        })
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setBookings({ response: response.data.data }));
    }),
    ignoreElements()
  );

const getEliteseekerBookings$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getEliteseekerBookings.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getEliteseekerBookings,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          if (payload) {
            dispatch(elitetutorSlice.actions.setBookingsPayload(payload));
          } else {
            payload = getBookingListPayload(state$.value);
          }
          if (payload) {
            dispatch(
              elitetutorSlice.actions.setBookingsType({
                type: payload.preset,
                viewAs: ViewAs.SEEKER,
              })
            );
            return from(
              elitetutorApi.getEliteseekerBookings(
                payload.userID,
                payload.size,
                payload.page,
                payload.preset
              )
            );
          } else {
            return EMPTY;
          }
        })
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setBookings({ response: response.data.data }));
    }),
    ignoreElements()
  );

const fetchVacancies$: RootEpic = (action$, state$, { dispatch, managed, elitetutorApi }) =>
  action$.pipe(
    filter(elitetutorSlice.actions.fetchVacancyList.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.fetchVacancyList,
      pipe(
        map((action) => action.payload.tutorID),
        switchMap((tutorID) => from(elitetutorApi.fetchVacancyList(tutorID)))
      )
    ),
    tap((response) => {
      dispatch(
        elitetutorSlice.actions.setVacancyListResponseState({ response: response.data.data })
      );
    }),
    ignoreElements()
  );

const updateAvailability$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.updateAvailability.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.UpdateAvailability),
    managed(
      elitetutorSlice.actions.updateAvailability,
      pipe(
        map((action) => action.payload),
        switchMap((payload) =>
          from(
            elitetutorApi.updateAvailability(
              payload.updateElitetutorVacanciesRequest,
              payload.tutorID
            )
          )
        )
      )
    ),
    tap((response) => {
      dispatch(
        elitetutorSlice.actions.setVacancyListResponseState({ response: response.data.data })
      );
      logSuccess(translate(translationKeys.timeSlotPicker.updateAvailabilitySuccess));
    }),
    ignoreElements()
  );

const getElitePaymentConfig$: RootEpic = (action$, state$, { dispatch, managed, elitetutorApi }) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getElitePaymentConfig.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.getElitePaymentConfig,
      pipe(
        map((action) => action.payload),
        switchMap(() => from(elitetutorApi.getElitePaymentConfig()))
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setElitePaymentConfig({ response: response.data.data }));
    }),
    ignoreElements()
  );

const createSubscriptionIntent$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.createSubscriptionIntent.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.createSubscriptionIntent,
      pipe(
        map((action) => action.payload),
        switchMap((payload) =>
          from(
            elitetutorApi.createSubscriptionIntent(mapMyPricingFormToRequest(payload.formValues))
          )
        )
      )
    ),
    tap((response) => {
      if (response?.data?.data?.paymentPage) {
        dispatch(navigationSlice.actions.navigateTo({ path: response.data.data.paymentPage }));
      }
    }),
    ignoreElements()
  );

const createSubscriptionIntentWithToken$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.createSubscriptionIntentWithToken.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.createSubscriptionIntentWithToken,
      pipe(
        map((action) => action.payload),
        switchMap((payload) =>
          from(
            elitetutorApi.createSubscriptionIntentWithToken({
              ...mapMyPricingFormToRequest(payload.formValues, payload.couponCode),
              token: payload.token,
            })
          )
        )
      )
    ),
    tap((response) => {
      if (response?.data?.data?.paymentPage) {
        dispatch(navigationSlice.actions.navigateTo({ path: response.data.data.paymentPage }));
      }
    }),
    ignoreElements()
  );

const getPersonalizedSubscriptionTokenData$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getPersonalizedSubscriptionTokenData.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.getPersonalizedSubscriptionTokenData,
      pipe(
        map((action) => action.payload),
        switchMap((payload) =>
          from(elitetutorApi.getPersonalizedSubscriptionTokenData(payload.token))
        )
      ),
      [
        (error) => {
          if (isUnauthenticatedError(error)) {
            logError(translate(translationKeys.pricing.errorTokenInvalid));
            return true;
          }
          return false;
        },
      ]
    ),
    tap((response) => {
      if (response?.data?.data) {
        dispatch(
          elitetutorSlice.actions.setPersonalizedSubscriptionTokenData({
            response: response.data.data,
          })
        );
      }
    }),
    ignoreElements()
  );

const getElitePaymentSeekerInfo$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getElitePaymentSeekerInfo.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getElitePaymentSeekerInfo,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => from(elitetutorApi.getElitePaymentSeekerInfo()))
      )
    ),
    tap((response) => {
      dispatch(
        elitetutorSlice.actions.setElitePaymentSeekerInfo({ response: response?.data?.data })
      );
    }),
    ignoreElements()
  );

const getEliteseekerCreditEvents$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getEliteseekerCreditEvents.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getEliteseekerCreditEvents,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          return from(
            elitetutorApi.getEliteSeekerCreditEvent(payload.userID, payload.size, payload.page)
          );
        })
      )
    ),
    tap((response) => {
      dispatch(
        elitetutorSlice.actions.setEliteseekerCreditEvents({ response: response.data.data })
      );
    }),
    ignoreElements()
  );

const cancelBooking$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.cancelBooking.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.cancelBooking,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          return from(elitetutorApi.cancelBooking(payload.seekerID, payload.bookingID)).pipe(
            map((response) => ({
              response,
              userID: payload.userID,
            }))
          );
        })
      )
    ),
    tap(({ response, userID }) => {
      const tutor = response.data.data?.tutor;
      const isDeletedUserOrNoOffer =
        (!tutor?.firstName && !tutor?.lastName) || !tutor?.$activeOfferId;

      dispatch(elitetutorSlice.actions.setCanceledBooking({ response: response.data.data }));
      logSuccess(translate(translationKeys.upComingLessons.lessonCard.cancelSuccessful));

      if (userID === tutor?._id || '') {
        dispatch(elitetutorSlice.actions.getElitetutorBookings(null));
      } else if (!isDeletedUserOrNoOffer) {
        dispatch(
          navigationSlice.actions.navigateTo({
            path: generatePath(AppRoute.BookEliteTutor, { offerID: tutor?.$activeOfferId }),
          })
        );
      } else {
        dispatch(elitetutorSlice.actions.getEliteseekerBookings(null));
      }
    }),
    ignoreElements()
  );

const getElitetutorCreditEvents$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getElitetutorCreditEvents.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getElitetutorCreditEvents,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          return from(
            elitetutorApi.getEliteTutorCreditEvent(payload.userID, payload.size, payload.page)
          );
        })
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setElitetutorCreditEvents({ response: response.data.data }));
    }),
    ignoreElements()
  );

const updatePayoutInfoInitialize$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(elitetutorSlice.actions.updatePayoutInfoInitialize.match),
    map(() => {
      const creditTutor = getCreditTutor(state$.value);
      const formValues = mapCreditTutorToForm(creditTutor);
      return formValues
        ? initialize(UPDATE_PAYOUT_INFO_FORM_NAME, formValues)
        : initialize(UPDATE_PAYOUT_INFO_FORM_NAME, {
            country: translate(translationKeys.earnings.updatePayoutInfo.country.defaultValue),
          });
    })
  );

const updatePayoutInfo$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated, ofValidReduxForm }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.updatePayoutInfo.match),
    ofValidReduxForm(UPDATE_PAYOUT_INFO_FORM_NAME, validateUpdatePayoutInfo),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.updatePayoutInfo,
      pipe(
        map((action) => action.payload),
        switchMap((payload) =>
          from(
            elitetutorApi.updateCreditTutorPayoutInfo(
              payload,
              getAuthenticatedUser(state$.value)?._id || ''
            )
          )
        )
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setCreditTutor({ response: response?.data?.data }));
      logSuccess(translate(translationKeys.earnings.updatePayoutInfo.updateSuccess));
      dispatch(modalSlice.actions.closeUpdatePayoutInfoModal());
    }),
    ignoreElements()
  );

const deletePayoutInfo$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.deletePayoutInfo.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.deletePayoutInfo,
      pipe(
        switchMap(() =>
          from(
            elitetutorApi.deleteCreditTutorPayoutInfo(getAuthenticatedUser(state$.value)?._id || '')
          )
        )
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setCreditTutor({ response: response?.data?.data }));
      logSuccess(translate(translationKeys.earnings.deletePayoutInfo.success));
      dispatch(modalSlice.actions.closeUpdatePayoutInfoModal());
    }),
    ignoreElements()
  );

const getCurrentCreditTutor$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getCurrentCreditTutor.match),
    debounceTime(API_MULTIPLY_CALLS_DEBOUNCE_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getCurrentCreditTutor,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => {
          return from(elitetutorApi.getCurrentCreditTutor(payload.userID));
        })
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setCreditTutor({ response: response.data.data }));
    }),
    ignoreElements()
  );

const requestManualPayout$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.requestManualPayout.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.requestManualPayout,
      pipe(
        switchMap(() =>
          from(elitetutorApi.requestManualPayout(getAuthenticatedUser(state$.value)?._id || ''))
        )
      )
    ),
    tap((response) => {
      dispatch(elitetutorSlice.actions.setCreditTutor({ response: response?.data?.data }));
      logSuccess(translate(translationKeys.earnings.manualPayout.updateSuccess));
    }),
    ignoreElements()
  );

const subscriptionChangePreview$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.subscriptionChangePreview.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.subscriptionChangePreview,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => from(elitetutorApi.subscriptionChangePreview(payload)))
      )
    ),
    tap((response) => {
      dispatch(
        elitetutorSlice.actions.setSubscriptionChangePreview({ response: response?.data?.data })
      );
    }),
    ignoreElements()
  );

const subscriptionChange$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.subscriptionChange.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.subscriptionChange,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => from(elitetutorApi.subscriptionChange(payload)))
      )
    ),
    tap((_) => {
      dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.Billing }));
    }),
    ignoreElements()
  );

const creditPurchase$: RootEpic = (action$, _, { dispatch, managed, elitetutorApi }) =>
  action$.pipe(
    filter(elitetutorSlice.actions.creditPurchase.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.creditPurchase,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => from(elitetutorApi.purchaseCredit(payload))),
        tap((response) => {
          dispatch(elitetutorSlice.actions.setCreditPurchase({ response: response?.data?.data }));
        })
      ),
      [
        (error) => {
          if (isUnauthenticatedError(error)) {
            logError(translate(translationKeys.creditPurchase.errorTokenInvalid));
            return true;
          } else if (isForbiddenError(error)) {
            logError(translate(translationKeys.creditPurchase.errorForbidden));
            return true;
          }
          return false;
        },
      ]
    ),
    ignoreElements()
  );

const promptCreditPurchase$: RootEpic = (action$, _, { managed, elitetutorApi }) =>
  action$.pipe(
    filter(elitetutorSlice.actions.promptCreditPurchase.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      elitetutorSlice.actions.promptCreditPurchase,
      pipe(
        map((action) => action.payload),
        switchMap((payload) => from(elitetutorApi.promptCreditPurchase(payload.seekerId)))
      ),
      [
        (error) => {
          const customErrorCode = error?.response?.data?.error;
          if (isNotFoundError(error)) {
            logError(translate(translationKeys.bookingForm.promptCreditPurchase.errorNotFound));
            return true;
          }
          if (isConflictError(error)) {
            if (customErrorCode === 'NOT_SUBSCRIBED') {
              logError(
                translate(translationKeys.bookingForm.promptCreditPurchase.errorNotSubscribed)
              );
              return true;
            }
            if (customErrorCode === 'MORE_THAN_ONCE_SUBSCRIBED') {
              logError(
                translate(
                  translationKeys.bookingForm.promptCreditPurchase.errorMoreThanOnceSubscribed
                )
              );
              return true;
            }
          }
          return false;
        },
      ]
    ),
    ignoreElements()
  );

const getEliteratings$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, elitetutorApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.getEliteratings.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      elitetutorSlice.actions.getEliteratings,
      pipe(switchMap(() => from(elitetutorApi.getEliteRatings()))),
      [
        () => {
          logError(translate(translationKeys.errors.eliteratingError));
          return true;
        },
      ]
    ),
    tap((response) => {
      return dispatch(elitetutorSlice.actions.setEliteratings({ response: response.data.data }));
    }),
    ignoreElements()
  );

const uploadEliterating$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, ofValidReduxForm, ofIsAuthenticated, elitetutorApi }
) =>
  action$.pipe(
    filter(elitetutorSlice.actions.uploadEliterating.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofValidReduxForm(EARN_MONEY_FORM_NAME, validateEarnMoneyForm),
    managed(
      elitetutorSlice.actions.uploadEliterating,
      pipe(
        map((action) => action.payload.formValues),
        switchMap((formValues: EarnMoneyFormValues) =>
          from(elitetutorApi.uploadEliterating(formValues))
        )
      )
    ),
    tap(() => {
      dispatch(elitetutorSlice.actions.getEliteratings());
      dispatch(modalSlice.actions.closeEliteratingModal());
    }),
    ignoreElements()
  );

export const eliteTutorEpic$ = combineEpics(
  applyAsEliteViaForm$,
  applyAsElite$,
  getSubjectApplications$,
  getElitetutorBookings$,
  getEliteseekerBookings$,
  updateAvailability$,
  fetchVacancies$,
  createBooking$,
  getElitePaymentConfig$,
  createSubscriptionIntent$,
  createSubscriptionIntentWithToken$,
  getPersonalizedSubscriptionTokenData$,
  getElitePaymentSeekerInfo$,
  getEliteseekerCreditEvents$,
  cancelBooking$,
  getElitetutorCreditEvents$,
  updatePayoutInfoInitialize$,
  updatePayoutInfo$,
  deletePayoutInfo$,
  getCurrentCreditTutor$,
  requestManualPayout$,
  subscriptionChangePreview$,
  subscriptionChange$,
  creditPurchase$,
  promptCreditPurchase$,
  getEliteratings$,
  uploadEliterating$
);
