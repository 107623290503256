export enum SubjectType {
  Math = 1,
  English = 2,
  GermanSchool = 3,
  Physics = 4,
  Latin = 5,
  French = 6,
  Chemistry = 7,
  Economy = 8,
  Biology = 9,
  TandemPartner = 10,
  Other = 11,
  Spanish = 12,
  Italian = 13,
  Geography = 14,
  History = 15,
  BWL = 16,
  ComputerScience = 17,
  LearningCoaching = 18,
  DaF = 19,
  Law = 20,
  Accounting = 21,
  Accountancy = 22,
  Russian = 23,
  LRSDyscalculia = 24,
  CoronaHelp = 25,
  GermanForRefugees = 26,
  ConversationTraining = 27,
}

export const HIDDEN_SUBJECTS = new Set<SubjectType>([SubjectType.GermanForRefugees]);

export const SEO_EXCLUDED_SUBJECT_SLUGS = new Set(['sonstige']);

export enum SubjectName {
  GermanForRefugees = 'Deutsch für Geflüchtete',
}

export enum SubjectSlug {
  GermanForRefugees = 'deutsch-fuer-gefluechtete',
}

export const SUBJECT_NAME_SOFT_HYPHEN_MAP = new Map<string, string>([
  ['Konversationskurs', 'Konversations&shy;kurs'],
  ['Konversationstraining', 'Konversations&shy;training'],
  ['Tandempartner', 'Tandem&shy;partner'],
  ['Rechnungswesen', 'Rechnungs&shy;wesen'],
  ['Lerncoaching', 'Lern&shy;coaching'],
]);
