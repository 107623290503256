export const customBadRequestErrorCodeList = [
  'VACANCY_SLOT_COUNT_WRONG',
  'VACANCY_BEFORE_THRESHOLD',
  'VACANCY_AFTER_THRESHOLD',
  'VACANCY_TOO_SHORT',
  'VACANCY_NOT_SLOT_ALIGNED',
  'BOOKING_BEFORE_THRESHOLD',
  'BOOKING_AFTER_THRESHOLD',
  'BOOKING_TOO_SHORT',
  'BOOKING_NOT_SLOT_ALIGNED',
  'SUBSCRIPTION_PLAN_NOT_FOUND',
  'PHONENUMBER_CHANGE_TIMEOUT',
  'INVALID_IMAGE_URL',
];

export const customConflictErrorCodeList = [
  'MISSING_VACANCY',
  'OTHER_BOOKING',
  'CONCURRENT_MODIFICATION',
  'FREE_BOOKING_UNAVAILABLE',
  'FREE_BOOKING_REQUIRED',
  'BOOKING_CREDIT_INSUFFICIENT',
  'SUBSCRIPTION_PLAN_MODIFIED',
  'BOOKING_CANCELLATION_ALREADY_REQUESTED',
  'PAYOUT_ALREADY_REQUESTED',
  'LAST_PAYOUT_TOO_RECENT',
  'PAYOUT_INFO_MISSING',
  'PROFILE_VIDEO_PENDING_APPROVAL',
  'QUOTA_EXHAUSTED',
  'ALREADY_SUBSCRIBED',
  'NOT_SUBSCRIBED',
  'MORE_THAN_ONCE_SUBSCRIBED',
  'CURRENT_SUBSCRIPTION_CHANGED',
  'SUBSCRIPTION_CHANGE_NO_ACTUAL_CHANGE',
  'PAYOUT_BALANCE_TOO_LOW',
  'SEEKER_NOT_FOUND',
];

export const customForbiddenErrorCodeList = [
  'WRONG_ACCOUNT_TYPE_TUTORING_INSTITUTE',
  'NOT_CURRENT_USER',
  'BOOKING_OWN_USER',
  'CANCEL_BOOKING_START_TOO_EARLY',
  'CANCEL_BOOKING_FREE',
];

export const customUnsupportedMediaTypeErrorCodeList = [
  'UNSUPPORTED_MEDIA_TYPE',
  'MEDIA_INFO_INVALID',
  'VIDEO_DURATION_LIMIT_EXCEEDED',
];
