import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../logic/validators/utils';
import { isRequiredValidator } from '../../logic/validators/validators';
import { TutoringKind } from './search.const';
import { PlaceSelectOptionValue } from 'logic/store/geolocation/geolocation.model';
import { SortOffersBy } from 'logic/api-models/sort-offer-by';
import { OfferSearchResponseDto, Subject } from 'logic/api-models/api-models';
import { TutoringRequestTeachingLevel } from 'logic/api-models/teaching-level';
import { SEO_EXCLUDED_SUBJECT_SLUGS } from 'models/subject-type';

export const SEARCH_FORM_NAME = 'search';
export const ELITE_SEARCH_FORM_NAME = 'elite-search';
export const REFUGEE_SEARCH_FORM_NAME = 'refugee-search';

export enum SearchFormFields {
  kind = 'kind',
  subject = 'subject',
  subjectName = 'subjectName',
  subjectSlug = 'subjectSlug',
  subjectSlugResolved = 'subjectSlugResolved',
  city = 'city',
  citySlug = 'citySlug',
  citySlugResolved = 'citySlugResolved',
  cityId = 'cityId',
  longitude = 'longitude',
  latitude = 'latitude',
  page = 'page',
  size = 'size',
  sortBy = 'sortBy',
  requestTeachingLevel = 'requestTeachingLevel',
}

export interface SearchFormValues {
  [SearchFormFields.kind]?: TutoringKind;
  [SearchFormFields.subject]?: Subject;
  [SearchFormFields.subjectName]?: string;
  [SearchFormFields.subjectSlug]?: string;
  [SearchFormFields.subjectSlugResolved]?: string;
  [SearchFormFields.city]?: PlaceSelectOptionValue;
  [SearchFormFields.citySlug]?: string;
  [SearchFormFields.citySlugResolved]?: string;
  [SearchFormFields.cityId]?: string;
  [SearchFormFields.longitude]?: number;
  [SearchFormFields.latitude]?: number;
  [SearchFormFields.page]?: number;
  [SearchFormFields.size]?: number;
  [SearchFormFields.sortBy]?: SortOffersBy;
  [SearchFormFields.requestTeachingLevel]?: TutoringRequestTeachingLevel;
}

export type SearchFormValidationErrors = FormErrors<SearchFormValues, string>;

export const validateSearchForm = (formValues: SearchFormValues) => {
  const { kind, city, sortBy } = formValues;

  const errors: SearchFormValidationErrors = {
    city:
      kind === TutoringKind.tutoringSchools || sortBy === SortOffersBy.Distance
        ? isRequiredValidator(city)
        : undefined,
  };

  return clearEmptyFields(errors);
};

export const determineNoIndexForSearchPage = (
  offers: OfferSearchResponseDto | undefined,
  page: number,
  subject: string
) => {
  return (
    (offers && 'totalCount' in offers && offers!.totalCount! <= 1) ||
    page > 1 ||
    SEO_EXCLUDED_SUBJECT_SLUGS.has(subject)
  );
};
